import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Header from './components/Header';
import QuoteSection from './components/QuoteSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className='bg-[#f7e5d3]' >
   <Navbar />
   <Banner />
  <Header/>
  <QuoteSection />
  <Footer />
    </div>
  );
}

export default App;
