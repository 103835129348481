import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap'; // Import React-Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import img1 from "../assets/img/pbgs-img.webp";
import img2 from "../assets/img/lg-pbgs.png";


export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <header className="py-3">
        <Container className='align-items-center'>
          <Row className="align-items-center justify-content-between">
            {/* Logo on the left */}
            <Col xs={12} md={2} className="text-center justify-content-center d-flex ">
              <img
                src={img2}
                alt="Logo"
                className="h-30 w-23 rounded-circle pb-logo"
              />
            </Col>

            {/* Text centered */}
            <Col xs={12} md={8} className="text-center">
              <h1 className="text-5xl text-[#74553e] pb-head " style={{ fontFamily: "niconne, fantasy" }}>Pune Bharat Gayan Samaj</h1>
              <p className="text-xl text-[#4e2f16] pb-par">SINCE 1911</p>
            </Col>

            {/* Social Media Icons in Column on the right */}
            <Col xs={12} md={2} className="d-flex justify-content-center ">
              <div className="d-none d-md-flex flex-column align-items-center  social-ic">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-[#4e2f16] hover:text-gray-800 mb-2">
                  <FaFacebookF size={20} />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-[#4e2f16] hover:text-gray-800 mb-2">
                  <FaInstagram size={20} />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-[#4e2f16] hover:text-gray-800 mb-2">
                  <FaTwitter size={20} />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-[#4e2f16] hover:text-gray-800">
                  <FaYoutube size={20} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Separator Line */}
        <hr className="border-t border-[#5e3b25] my-2 mx-auto w-full max-w-6xl" />

        {/* Navigation Links */}
        <Navbar expand="lg" className="bg-transparent">
          <Container>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav">
              <Nav className="mx-auto gap-4 ">
                <Nav.Link href="#" className="text-[#5e3b25] hover:text-gray-500">
                  HOME
                </Nav.Link>
                <Nav.Link href="#" className="text-[#4e2f16] hover:text-gray-500">
                  ABOUT US
                </Nav.Link>
                <Nav.Link href="#" className="text-[#9b8168] hover:text-gray-500">
                  BHASKAR SANGEET VIDYALAY
                </Nav.Link>
                <Nav.Link href="#" className="text-[#9b8168] hover:text-gray-500">
                  EVENTS
                </Nav.Link>
                <Nav.Link href="#" className="text-[#9b8168] hover:text-gray-500">
                  ARCHIVAL
                </Nav.Link>
                <Nav.Link href="#" className="text-[#9b8168] hover:text-gray-500">
                  CREDENTIALS
                </Nav.Link>
                <Nav.Link href="#" className="text-[#9b8168] hover:text-gray-500">
                  CONTACT
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* <section className="banner d-flex justify-content-center align-items-center ">
  <img
    src={img1}
    alt="Banner Image"
    className="img-fluid"
    style={{ maxWidth: "73%", height: "auto" }} // Ensures responsive sizing
  />
</section> */}
    </div>
  );
}
