import React from "react";
import img1 from "../assets/img/pbgs-img.webp";

const Banner = () => {
    return (
        <div>
        <section className="banner d-flex justify-content-center align-items-center ">
  <img
    src={img1}
    alt="Banner Image"
    className="ban-img"
   
  />
</section> 
        </div>
    )
}
export default Banner;