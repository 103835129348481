import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa'; // Social Icons


export default function Footer() {
  return (
    <footer className="pb-5 pt-3">
      <Container>
        {/* Separator Line Above Footer */}
        <div className="mb-4" style={{ borderTop: '2px solid #60391a' }}></div>

        <Row className="text-center text-md-left">
          {/* First Section: About Us */}
          <Col xs={12} md={3} className=" mb-md-0">
            <p className="footer-text text-dark mb-2">© 2021 BY PUNE BHARAT GAYAN SAMAJ</p>
          </Col>

          {/* Separator Between Sections */}
          <Col xs={12} className="d-md-none">
            <div className="my-4" ></div>
          </Col>

          {/* Second Section: Contact */}
          <Col xs={12} md={3} className=" mb-md-0">
            <p className="footer-text text-dark mb-2">CONTACT NO</p>
            <p className="footer-text text-secondary mb-2">Telephone: 020-2445 3937</p>
            <p className="footer-text text-secondary">Mobile: 9822056008</p>
          </Col>

          {/* Separator Between Sections */}
          <Col xs={12} className="d-md-none">
            <div className="my-4" ></div>
          </Col>

          {/* Third Section: Address */}
          <Col xs={12} md={3} className=" mb-md-0">
            <p className="footer-text text-dark mb-2">ADDRESS</p>
            <p className="footer-text text-secondary">
              PUNE BHARAT GAYAN SAMAJ
              <br />
              E 1486, Shukrawar Peth, Bajirao Road, Near Shanipar,
              <br />
              Pune – 411 002. Maharashtra, India
            </p>
          </Col>

          {/* Separator Between Sections */}
          <Col xs={12} className="d-md-none">
            <div className="my-4" ></div>
          </Col>

          {/* Fourth Section: Social Media */}
          <Col xs={12} md={3} className=" mb-md-0">
            <p className="footer-text text-dark mb-2">FOLLOW US</p>
            <div className="d-flex justify-content-center ">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-dark mr-3">
                <FaFacebook size={20} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-dark mr-3">
                <FaTwitter size={20} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-dark mr-3">
                <FaInstagram size={20} />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-dark">
                <FaYoutube size={20} />
              </a>
            </div>
          </Col>
        </Row>

        {/* Separator Line Below Footer */}
        <div className="mt-4" style={{ borderTop: '2px solid #60391a' }}></div>
      </Container>
    </footer>
  );
}
