import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function QuoteSection() {
  return (
    <section className="py-4 ">
      <Container>
        <Carousel indicators={true} controls={true} interval={null} className="text-center dark-carousel">
          <Carousel.Item>
            <blockquote className="blockquote text-center px-5 py-4 quote-block">
              <p className=" font-italic quote-text">
                <span className="quote-icon-left">“</span>
                Since its inception, the Samaj has been instrumental in helping the Indian audiences to develop a liking and appreciation of the inner and outer beauty of the spirit of Indian Classical Music, its technicalities, and creative, spiritual appeal.
                <span className="quote-icon-right">”</span>
              </p>
            </blockquote>
          </Carousel.Item>
          <Carousel.Item>
            <blockquote className="blockquote text-center px-5 py-4 quote-block">
              <p className=" font-italic quote-text">
                <span className="quote-icon-left">“</span>
                The essence of Indian Classical Music lies in its power to connect the soul with divinity, blending technical mastery with a profound emotional resonance that inspires and elevates.
                <span className="quote-icon-right">”</span>
              </p>
            </blockquote>
          </Carousel.Item>
          <Carousel.Item>
            <blockquote className="blockquote text-center px-5 py-4 quote-block">
              <p className=" font-italic quote-text">
                <span className="quote-icon-left">“</span>
                Indian Classical Music, with its soulful melodies and intricate rhythms, serves as a bridge between tradition and transcendence, inviting listeners to immerse themselves in a rich tapestry of cultural and spiritual heritage
                <span className="quote-icon-right">”</span>
              </p>
            </blockquote>
          </Carousel.Item>
        </Carousel>
      </Container>

      <div className=' d-flex justify-content-center align-items-center mt-4'>
        <a
          href="https://portal.punebharatgayansamaj.com/"
          target="_blank"
          
        >
          <button className="register-button">
            Click Here for Franchise Registration
          </button>
        </a>
        </div>
    </section>
  );
}
