import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import img3 from "../assets/img/pbgs1.png"

export default function Header() {
  return (
    <header className="my-4 py-3">
      <Container>
        <Row className="justify-content-center text-center">
          <Col xs={12} md={10} lg={8}>
            <h2 className="display-8 font-kepler-w03-light-scd-cp text-[rgb(96,57,26)]">
              Pune Bharat Gayan Samaj
            </h2>
            <p className="text-[rgb(96,57,26)] font-palatino ">
            An institute devoted to Indian Classical Music not just an institute, but a school of thought that took upon itself to enrich people with the riches of this music. Established under the patronage of the 'Kirloskar Natak Mandali' on 1st Sept. 1911, it later came to be known as Pune Bharat Gayan Samaj. 
            </p>
          </Col>
        </Row>

        {/* <div className=' d-flex justify-content-center align-items-center mt-2'>
        <a
          href="https://portal.punebharatgayansamaj.com/"
          target="_blank"
          
        >
          <button className="register-button">
            Click Here for Franchise Registration
          </button>
        </a>
        </div> */}
        <div className='d-flex justify-content-center align-items-center mt-4'>
            <img src={img3}/>
        </div>
      </Container>
    </header>
  );
}
